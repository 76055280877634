@import '../../colors/colors.scss';

.erro404-display-login {
  min-height: calc(-6rem + 100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}
.erro404-display-logout {
  min-height: calc(-3rem + 100vh);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: auto;
}
.erro404-title {
  font-size: 8rem;
  color: $primary_system;
  font-weight: bold;
}
.erro404-divisor {
  width: 50%;
  margin: 0 auto;
  border-bottom: 3px solid $primary_system_alpha;
}
.erro404-subTitle {
  font-size: 3rem;
  color: $primary_system_active;
  font-weight: bold;
}
.erro404-underSubTitle {
  margin-bottom: 50px !important;
  color: $primary_system;
  font-weight: bold;
}
.erro404-link {
  text-decoration: none !important;
}
.erro404-btn {
  background-color: $primary_system !important;
  border: none !important;
  &:hover {
    background-color: $primary_system_active !important;
    border: none;
  }
}
