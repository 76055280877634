@import '../../colors/colors.scss';

.billing-display {
  min-height: calc(-3rem + 100vh);
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.billing-icon-display {
  width: 8rem;
}
.billing-icon {
  font-size: 8rem;
  color: $primary_system;
}
.billing-title {
  font-size: 4rem;
  color: $primary_system;
  font-weight: bold;
}
.billing-divisor {
  width: 90%;
  margin: 0 auto;
  border-bottom: 3px solid $primary_system_alpha;
}
.billing-subTitle {
  font-size: 1rem;
  color: $primary_system_active;
  font-weight: bold;
}
.billing-button-contact {
  text-decoration: none;
  background-color: $link_cansho !important;
  border: none !important;
  &active {
    border-color: $link_cansho !important;
    box-shadow: inset 0 0 0 1px $link_cansho !important;
    transition: 0.5s;
  }
}
.billing-button-contact:hover {
  background-color: $green !important;
}
