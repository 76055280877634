@import '../../colors/colors.scss';

.link-pointer {
  cursor: pointer;
}

.button-primary {
  background-color: $primary_system !important;
  border-color: $primary_system !important;
  color: $white;
  &:hover {
    background-color: $primary_system_darker !important;
    border-color: $primary_system !important;
    transition: 0.5s;
  }
  &:active {
    background-color: $primary_system_lighter !important;
    border-color: $primary_system !important;
  }
  &:focus {
    background-color: $primary_system_lighter !important;
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
}

.button-confirmation {
  background-color: $success !important;
  border: 1px solid $success !important;
  &:hover {
    color: $white !important;
    background-color: $green !important;
    border: 1px solid $green !important;
  }
  &:active {
    border-color: $green !important;
    box-shadow: inset 0 0 0 1px $green !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $green !important;
    box-shadow: inset 0 0 0 1px $green !important;
    transition: 0.5s;
  }
}

.button-savelist {
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
  &:hover {
    color: $white !important;
    background-color: $primary_system_lighter !important;
    border: 1px solid $primary_system_lighter !important;
  }
  &:active {
    border-color: $primary_system_lighter !important;
    box-shadow: inset 0 0 0 1px $primary_system_lighter !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $primary_system_lighter !important;
    box-shadow: inset 0 0 0 1px $primary_system_lighter !important;
    transition: 0.5s;
  }
}

.button-contradict {
  background-color: $danger !important;
  border: 1px solid $danger !important;
  &:hover {
    color: $white !important;
    background-color: $red !important;
    border: 1px solid $red !important;
  }
  &:active {
    border-color: $red !important;
    box-shadow: inset 0 0 0 1px $red !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $red !important;
    box-shadow: inset 0 0 0 1px $red !important;
    transition: 0.5s;
  }
}

.button-contest {
  background-color: $primary_system_darker !important;
  border: 1px solid $primary_system_darker !important;
  &:hover {
    color: $white !important;
    background-color: $primary_system !important;
    border: 1px solid $primary_system !important;
  }
  &:active {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
    transition: 0.5s;
  }
}

.button-icon-square {
  height: 2rem;
  width: 2rem;
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
  &:hover {
    color: $primary_system_lighter !important;
    background-color: $white !important;
    border: 1px solid $primary_system_lighter !important;
    box-shadow: inset 0 0 0 1px $primary_system_lighter !important;
  }
  &:active {
    border-color: $primary_system_lighter !important;
    box-shadow: inset 0 0 0 1px $primary_system_lighter !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $primary_system_lighter !important;
    box-shadow: inset 0 0 0 1px $primary_system_lighter !important;
    transition: 0.5s;
  }
}

.button-scroll-top {
  opacity: 0.6;
  background-color: $primary_system !important;
  border-color: $primary_system !important;
  color: $white;
  &:hover {
    background-color: $primary_system_darker !important;
    border-color: $primary_system !important;
    transition: 0.5s;
  }
  &:active {
    background-color: $primary_system_lighter !important;
    border-color: $primary_system !important;
  }
  &:focus {
    background-color: $primary_system_lighter !important;
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
}
