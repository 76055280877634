@import '../../colors/colors.scss';

.item-display {
  border-left: 2px solid $primary_system;
  border-bottom: 0.5px solid $primary_system_lighter;
  background-color: $primary_system;
  height: 3.2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    border-left: 2px solid $secondary_system;
    background-color: $primary_system_active;
    cursor: pointer;
  }
}

.item-display-active {
  background-color: $primary_system_active;
  border-left: 2px solid $secondary_system;
  height: 3.2rem;
}

.item-display-sub-item {
  background-color: $primary_system;
  border-bottom: 0.5px solid $primary_system_lighter;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

.item-display-main-sub-item-active {
  height: 3.2rem;
  width: 100% !important;
  background-color: $primary_system_active;
  border-left: 2px solid $secondary_system;
  cursor: pointer;
}

.item-box-sub-item {
  width: 100% !important;
}

.item-display-main-sub-item {
  height: 3.2rem;
  width: 100% !important;
}

.item-display-sub-route {
  border-left: 2px solid $primary_system;
  background-color: $primary_system;
  height: 3.2rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  &:hover {
    border-left: 2px solid $secondary_system;
    background-color: $primary_system_active;
  }
}

.item-icon {
  color: $white;
  font-size: 1.3rem;
}

.item-icon-active {
  color: $white;
  font-size: 1.3rem;
}

.item-icon-arrow-sub-item {
  color: $white;
  font-size: 1.3rem;
  cursor: pointer !important;
}

.item-icon-sub-arrow {
  color: $white;
  font-size: 1rem;
  transform: rotate(90deg);
}

.item-icon-sub-item {
  color: $white;
  font-size: 1rem;
}

.no-decoration {
  text-decoration: none;
  &:active {
    color: $secondary_system;
  }
}

.item-text-extended {
  font-size: 1rem;
  transition: font-size 0.5s;
  color: $white;
}

.item-text-closed {
  font-size: 0rem;
  color: $white;
}

.item-menu-action {
  width: 6rem !important;
  height: 3rem !important;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  font-size: 0.8rem !important;
  text-decoration: none !important;
}
.item-menu-list {
  width: 6rem !important;
  height: 2rem !important;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  font-size: 0.8rem !important;
}
.item-menu-action-responsive {
  width: 17rem !important;
  height: 2.2rem !important;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  font-size: 0.95rem !important;
}
.item-menu-list-responsive {
  width: 7rem !important;
  height: 2.2rem !important;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  font-size: 0.95rem !important;
}

.item-selected-list-responsive {
  width: 7rem !important;
  height: 2.2rem !important;
  background-color: $primary_system_alpha !important;
  border-radius: 0px !important;
  border-color: $primary_system_alpha !important;
  color: $secondary_system !important;
  font-size: 0.95rem !important;
}
.item-menu-order-responsive {
  width: 8rem !important;
  height: 2.2rem !important;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  font-size: 0.95rem !important;
}
.item-menu-order-await-responsive {
  width: 12rem !important;
  height: 2.2rem !important;
  background-color: orange !important;
  border-radius: 0px !important;
  border-color: orange !important;
  color: $secondary_system !important;
  font-size: 0.95rem !important;
}
.item-menu-order-responsive-separated {
  width: 8rem !important;
  height: 2.2rem !important;
  background-color: $primary_system_alpha !important;
  border-radius: 0px !important;
  border-color: $primary_system_alpha !important;
  color: $secondary_system !important;
  font-size: 0.95rem !important;
}
.item-menu-order-responsive-square {
  width: 8rem !important;
  height: 2.2rem !important;
  background-color: rgba(255, 193, 193, 0.6);
  border-radius: 0px !important;
  border-color: rgba(255, 193, 193, 0.6);
  color: $secondary_system !important;
  font-size: 0.95rem !important;
}
