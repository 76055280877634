@import '../../colors/colors.scss';
@import './fonts.scss';

body {
  margin: 0;
  background: $background_system;
  font-family: 'Poppins-Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.scroller {
  scrollbar-width: thin;
}

.default-box {
  background: $white;
  height: calc(-9rem + 100vh);
  overflow: auto;
}

.default-box-pagination {
  background: $white;
  height: calc(-12rem + 100vh);
  overflow: auto;
}

.default-box-header {
  width: 100%;
}

.default-box-header-search-clear {
  color: $danger !important;
  cursor: pointer;
}

.vh-100 {
  height: 100vh;
}

.item-box {
  min-height: 3rem;
  background-color: $background_system;
  &:hover {
    background-color: $item_hover;
    transition: background-color 0.5s;
  }
}

.p-float-label > label {
  left: 0.2rem !important;
}

@media screen and (max-width: 900px) {
  .item-box-col {
    width: 100% !important;
    text-align: left !important;
  }
}

.item-box-col-name {
  display: inline-block;
  width: 20% !important;
}

.item-box-col-content {
  display: inline-block;
  width: 80% !important;
  max-width: 2rem;
  height: 10rem;
}

.item-spacing-image {
  height: 3.3rem;
  // cursor: pointer;
  width: 3.3rem;
  border-radius: 5px;
  border: 2px solid $white !important;
  background-color: $background_system;
  margin: 0%;
}

.item-box-image {
  max-height: 3rem;
  // cursor: pointer;
  max-width: 3rem;
  border-radius: 5px;
  padding: 0%;
  margin: 0%;
}
.item-action-button {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
  z-index: 1;
  &:active {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
}

.item-see-button {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $text_system !important;
  border: 1px solid $text_system !important;
  &:active {
    border-color: $blue !important;
    box-shadow: inset 0 0 0 1px $blue !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $blue !important;
    box-shadow: inset 0 0 0 1px $blue !important;
    transition: 0.5s;
  }
}

.item-edit-button {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $success !important;
  border: 1px solid $success !important;
  &:active {
    border-color: $green !important;
    box-shadow: inset 0 0 0 1px $green !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $green !important;
    box-shadow: inset 0 0 0 1px $green !important;
    transition: 0.5s;
  }
}

.item-delete-button {
  height: 1.5rem;
  width: 1.5rem;
  background-color: $danger !important;
  border: 1px solid $danger !important;
  &:active {
    border-color: $pink !important;
    box-shadow: inset 0 0 0 1px $pink !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $pink !important;
    box-shadow: inset 0 0 0 1px $pink !important;
    transition: 0.5s;
  }
}

.item-button-icon {
  font-size: 1.2rem;
}

.item-action-button-icon {
  font-size: 0.9rem;
}

.item-action-arrow {
  font-size: 1.2rem;
  position: relative;
  color: $primary_system;
  right: 0.5rem;
  top: 0.15rem;
  z-index: 0;
}

.action-button-extended {
  width: 0px !important;
  display: none !important;
  overflow: hidden;
}

.action-button-closed {
  width: auto !important;
  display: inline !important;
}

.pagination-box {
  height: 2.5rem;
  background-color: $white;
}

.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 1px $primary_system;
  border-color: $primary_system !important;
}

.settings-input:focus {
  border-color: #942222 !important;
  box-shadow: inset 0 0 0 1px #942222 !important;
}

.pagination-button {
  height: 1.5rem;
  width: 1.5rem;
  border-radius: 0px;
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
  &:active {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
}

.pagination-text {
  font-size: 1rem;
}

.modal-header-button {
  height: 2rem;
  width: 2rem;
  cursor: default;
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
}

.modal-header-icon {
  color: $white !important;
}

.modal-save-button {
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  min-width: 6rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}

.modal-cancel-button {
  background-color: $danger !important;
  border-radius: 0px !important;
  border-color: $danger !important;
  color: $secondary_system !important;
  min-width: 6rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $pink !important;
  }
}

.accept-button {
  background-color: $success !important;
  border-radius: 0px !important;
  border-color: $success !important;
  color: $secondary_system !important;
  min-width: 6rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}

.disabled-button {
  background-color: $muted_system !important;
  border-radius: 0px !important;
  border-color: $muted_system !important;
  color: $secondary_system !important;
  min-width: 6rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}

.reject-button {
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  min-width: 6rem;
}

.accept-signout-button {
  background-color: $danger !important;
  border-radius: 0px !important;
  border-color: $danger !important;
  color: $secondary_system !important;
  min-width: 6rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $danger !important;
  }
}

.link {
  cursor: pointer;
  text-decoration: none;
  color: $primary_system_alpha;
}

.toast-success {
  background-color: #d4edda !important;
  color: #155724 !important;
}

.toast-success .Toastify__close-button {
  color: #155724;
}

.toast-warn {
  background-color: #fff3cd !important;
  color: #856404 !important;
}

.toast-warn .Toastify__close-button {
  color: #856404;
}

.toast-error {
  background-color: #f8d7da !important;
  color: #721c24 !important;
}

.toast-error .Toastify__close-button {
  color: #721c24;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  position: absolute;
}
::-webkit-scrollbar-thumb {
  background: $primary_system_active;
}
::-webkit-scrollbar-thumb:hover {
  background: $primary_system_active;
}
::-webkit-scrollbar-track {
  background: $primary_system;
  cursor: pointer;
}

#map {
  height: 25rem;
}

.width100 {
  width: 100%;
}
.width16-6 {
  width: 16.6%;
}
.width90 {
  width: 90%;
}
.width20 {
  width: 20%;
}
.width10 {
  width: 10% !important;
}
.cursor-pointer {
  cursor: pointer;
}
.white {
  background-color: white;
}

.spinner {
  --size: 62px;

  width: var(--size);
  height: var(--size);

  border-radius: 50%;
  perspective: 800px;
}

.inner {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 3px solid transparent;
}

.inner.one {
  left: 0;
  top: 0;
  animation: rotate-one 1s linear infinite;
  border-bottom: 3px solid #2cfffe;
}

.inner.two {
  right: 0;
  top: 0;
  animation: rotate-two 1s linear infinite;
  border-right: 3px solid #fcc10f;
}

.inner.three {
  right: 0;
  bottom: 0;
  animation: rotate-three 1s linear infinite;
  border-top: 3px solid #ec1b60;
}

@keyframes rotate-one {
  0% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(-45deg) rotateZ(360deg);
  }
}

@keyframes rotate-two {
  0% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(50deg) rotateY(10deg) rotateZ(360deg);
  }
}

@keyframes rotate-three {
  0% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(0deg);
  }
  100% {
    transform: rotateX(35deg) rotateY(55deg) rotateZ(360deg);
  }
}

.color-white {
  color: white;
}
