@import '../../colors/colors.scss';

.users-add-button {
  height: 2rem;
  width: 2rem;
  background-color: $success !important;
  border: 1px solid $success !important;
  &:hover {
    color: $success !important;
    background-color: $white !important;
    border: 1px solid $success !important;
  }
  &:active {
    border-color: $green !important;
    box-shadow: inset 0 0 0 1px $green !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $green !important;
    box-shadow: inset 0 0 0 1px $green !important;
    transition: 0.5s;
  }
}

.users-filter-button {
  height: 2rem;
  width: 2rem;
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
  &:hover {
    color: $primary_system !important;
    background-color: $white !important;
    border: 1px solid $primary_system !important;
  }
  &:active {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
}

.users-search-button {
  height: 2rem;
  width: 2rem;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border: 1px solid $primary_system !important;
  &:active {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
}

.users-search-input {
  width: 14rem;
  height: 2rem;
  color: $primary_system_alpha !important;
  border-color: $primary_system_alpha !important;
  border-radius: 0px !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.users-button-icon {
  font-size: 1.2rem;
}

.users-button-text {
  font-size: 1.2rem;
}
