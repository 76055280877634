@import '../../styles/global/colors.scss';

.basic-modal-footer {
}

.button-accept {
  border-radius: 0px;
  background-color: $primary_system !important;
  color: $white;
  border: 1px solid $primary_system !important;
  &:hover {
    background-color: $primary_system_darker !important;
    border: 1px solid $primary_system_darker !important;
  }
  &:active {
    background-color: $primary_system !important;
    border: 1px solid $primary_system !important;
  }
  &:focus {
    box-shadow: none;
  }
}
