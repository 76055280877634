.order-badge-order-created {
  background: gray;
  color: #eeeded;
}
.order-badge-order-processing {
  background-color: lightblue;
  color: #23547b;
}
.order-badge-order-processed {
  background: rgb(24, 24, 194);
  color: #ffffff;
}
.order-badge-order-sended {
  background-color: #ffd8b2;
  color: #805b36;
}
