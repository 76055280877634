// Largura

.width10 {
  width: 10% !important;
}

.width15 {
  width: 15% !important;
}

.width20 {
  width: 20% !important;
}

.width25 {
  width: 25% !important;
}

.width30 {
  width: 30% !important;
}
.width33 {
  width: 33% !important;
}

.width35 {
  width: 35% !important;
}

.width40 {
  width: 40% !important;
}

.width45 {
  width: 45% !important;
}

.width50 {
  width: 50% !important;
}

.width55 {
  width: 55% !important;
}

.width60 {
  width: 60% !important;
}

.width65 {
  width: 65% !important;
}

.width70 {
  width: 70% !important;
}

.width75 {
  width: 75% !important;
}

.width80 {
  width: 80% !important;
}

.width85 {
  width: 85% !important;
}

.width90 {
  width: 90% !important;
}

.width95 {
  width: 95% !important;
}

.width100 {
  width: 100% !important;
}

.widthAuto {
  width: auto !important;
}

// Altura

.height10 {
  height: 10% !important;
}

.height15 {
  height: 15% !important;
}

.height20 {
  height: 20% !important;
}

.height25 {
  height: 25% !important;
}

.height30 {
  height: 30% !important;
}

.height35 {
  height: 35% !important;
}

.height40 {
  height: 40% !important;
}

.height45 {
  height: 45% !important;
}

.height50 {
  height: 50% !important;
}

.height55 {
  height: 55% !important;
}

.height60 {
  height: 60% !important;
}

.height65 {
  height: 65% !important;
}

.height70 {
  height: 70% !important;
}

.height75 {
  height: 75% !important;
}

.height75-vh {
  height: 75vh !important;
}

.height80 {
  height: 80% !important;
}

.height85 {
  height: 85% !important;
}

.height90 {
  height: 90% !important;
}

.height95 {
  height: 95% !important;
}

.height100 {
  height: 100% !important;
}

.heightAuto {
  height: auto !important;
}

.height1Rem {
  height: 1rem !important;
}

.height1-5Rem {
  height: 1.5rem !important;
}

.height2Rem {
  height: 2rem !important;
}

.height2-5Rem {
  height: 2.5rem !important;
}

.height3Rem {
  height: 3rem !important;
}

.height4Rem {
  height: 4rem !important;
}
.height75-8 {
  height: calc(75vh - 8rem);
}
.height38-8 {
  height: calc(38vh - 8rem);
}
.height100-23 {
  height: calc(100vh - 23rem);
}

// Novos

.height18-rem {
  height: 18rem;
}
.height5-rem {
  height: 5rem;
}
.height7-rem {
  height: 7rem;
}
.height4Rem {
  height: 4rem;
}
.height37Rem {
  height: 37rem;
}
.height19Rem {
  height: 19rem;
}
.height12-8Rem {
  height: 12.8rem;
}
.height5-5Rem {
  height: 5rem;
}

.width33 {
  width: 33.33%;
}

.width33-33 {
  width: 33.33%;
}
.height2-2Rem {
  height: 2.2rem;
}
.height20Rem {
  height: 20rem;
}
.height10Rem {
  height: 10rem;
}
.height6Rem {
  height: 6rem;
}
.height7Rem {
  height: 7rem;
}
.height8Rem {
  height: 8rem;
}
.height14Rem {
  height: 14rem;
}
.height9Rem {
  height: 9rem;
}
.width3-4Rem {
  width: 3.4rem;
}
.width0-3Rem {
  width: 0.3rem;
}
.height77Vh {
  height: 77vh;
}
