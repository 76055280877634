@import '../../colors/colors.scss';

.login-display {
  height: calc(-3rem + 100vh);
  min-height: 30rem;
}

.login-background {
  background-image: url('../../assets/images/login-background.jpg');
  background-size: cover;
}

.login-input-text {
  width: 18rem;
  height: 3rem;
  color: $primary_system_alpha !important;
  border-color: $primary_system_alpha !important;
  border-radius: 0px !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.login-button {
  width: 14rem !important;
  height: 3rem !important;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
}
