@import '../../global/colors.scss';

.editAmount-title {
  font-family: 'Poppins-Bold';
  font-size: 1.1rem;
  color: $primary_system;
}

.editAmount-log {
  border: 1px solid $item-hover;
  border-radius: 5px;
}

.editAmount-log-title {
  font-family: 'Poppins-Bold';
  font-size: 0.9rem;
}

.editAmount-log-title span {
  font-family: 'Poppins-Bold';
  font-size: 0.9rem;
  color: $primary_system;
}

.editAmount-log-icon {
  font-family: 'Poppins-Bold';
  text-align: center;
  font-size: 1.5rem;
  border-right: 1px solid $primary_system;
  color: $primary_system;
}
