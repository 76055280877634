@import '../../global/colors.scss';

.exportProducts-subtitle {
  font-family: 'Poppins-Bold';
}

.exportProducts-subtitle span {
  color: $primary_system;
}

.exportProducts-checkbox {
  font-family: 'Poppins-Bold';
}

.exportProducts-checkbox-checked div {
  background: $primary_system !important;
  border-color: $primary_system !important;
  box-shadow: none !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:active {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:hover {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
}

.exportProducts-checkbox-unchecked div {
  background: $white !important;
  border-color: $primary_system !important;
  box-shadow: none !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:active {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:hover {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
}

.exportProducts-dropdown-title {
  font-family: 'Poppins-Bold';
  margin-bottom: 0px;
}

.exportProducts-dropdown {
  width: 75%;
  background-color: $background_system !important;
  border: 1px solid $primary_system !important;
  color: black !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.exportProducts-dropdown span {
  width: 75%;
  background-color: $background_system !important;
  border: none !important;
  border-color: $primary_system !important;
  color: black !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}
