@import '../../colors/colors.scss';

.input-text-muted {
  background-color: $background_system !important;
  border: none !important;
  border-radius: 0px !important;
  color: black !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.new-input-text {
  position: relative;
  top: 0.6rem;
  left: 0.37rem;
  background-color: white;
  z-index: 1;
  font-size: 0.91rem;
  color: $primary_system;
}

.new-input-size {
  border: 1px solid $primary_system !important;
  width: 100% !important;
  background-color: white !important;
  color: black !important;
}
