@import '../../colors/colors.scss';

.help-icon-background {
  width: 2rem;
  height: 2rem;
  background-color: $primary_system;
}
.help-icon {
  font-size: 1.5rem;
  color: $white;
}
.help-title {
  font-size: 1.5rem;
}
.help-content-header {
  font-size: 1.3rem;
}
.help-content-body {
  font-size: 0.85rem;
}
@media screen and (max-width: 300px) {
  .help-navigation {
    height: 6rem !important;
  }
}

.help-navigation {
  background-color: $primary_system;
  height: 4.5rem;
}

.help-ClientCode {
  color: $white;
}
.help-ClientCode > span {
  font-size: 1.2rem;
}
.help-button-copyId:hover {
  color: $warning;
  cursor: pointer;
}
.help-button {
  background-color: $white;
  font-size: 0.65rem;
  width: 10rem;
  border: none;
  outline: none;
  cursor: pointer;
}
.help-button:hover {
  background-color: $primary_system_active;
  color: white;
}
