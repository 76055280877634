@import '../../colors/colors.scss';

.editListItems-tab {
  cursor: pointer;
  border: 1px solid $primary_system !important;
  font-size: 1.1rem;
  &:hover {
    transition: 0.2s;
    font-size: 1.1rem;
    background-color: $primary_system;
    color: white;
  }
}

.editListItems-active-tab {
  background-color: $primary_system;
  cursor: pointer;
  color: white;
  font-size: 1.1rem;
}

.editListItems-product-item {
  min-height: 3rem;
  background-color: $background_system;
  &:hover {
    background-color: $item_hover;
    transition: background-color 0.5s;
  }
}

.editList-input {
  width: 100%;
  height: 2rem;
  background-color: white !important;
  border: 1px solid $primary_system !important;
  &:focus {
    border: 1.2px solid $primary_system !important;
  }
}

.editList-select-all {
  background-color: $primary_system !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  height: 2rem;
  &:hover {
    transition: 0.2s;
    background-color: $secondary_system !important;
    color: $primary_system !important;
  }
}

.editList-radio-button .p-radiobutton-box {
  border-color: $primary_system !important;
}

.editList-radio-button .p-radiobutton-box .p-highlight .p-focus {
  box-shadow: none !important;
}

.editList-radio-button-selected .p-radiobutton-box-focused {
  box-shadow: $primary_system !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px red !important;
    color: red;
  }
  &:active {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px red !important;
    color: red;
  }
}

.editList-radio-button-selected .p-radiobutton-box {
  border-color: $primary_system !important;
}

.editList-radio-button-selected .p-radiobutton-icon {
  background-color: $primary_system !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:active {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:hover {
    border-color: $primary_system !important;
  }
}
