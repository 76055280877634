@import '../../colors/colors.scss';

.filter-add-button {
  background-color: $white !important;
  border-color: $primary_system !important;
  color: $primary_system !important;
  height: 2rem;
  &:hover {
    transition: 0.2s;
    background-color: $primary_system !important;
    color: $white !important;
  }
}
