@import '../../colors/colors.scss';

.settings-icon-background {
  width: 2rem;
  height: 2rem;
  background-color: $primary_system;
}
.settings-icon {
  font-size: 1.5rem;
  color: $white;
}
.settings-title {
  font-size: 1.5rem;
}

.settings-input {
  width: 100%;
  background-color: $background_system !important;
  border: none !important;
  color: black !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.settings-input-invalid {
  width: 100%;
  background-color: $background_system !important;
  border: 1px solid $danger !important;
  &:hover {
    border-color: $danger !important;
  }
  &:focus {
    border-color: $danger !important;
    box-shadow: inset 0 0 0 1px $danger !important;
  }
}

.settings-dropdown span {
  width: 100%;
  background-color: $background_system !important;
  border: none !important;
  border-color: $primary_system !important;
  color: black !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.settings-dropdown-test {
  width: 80%;
  background-color: $background_system !important;
  border: 1px solid black !important;
  color: black !important;
  &:active {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.settings-input-invalid {
  width: 100%;
  background-color: $background_system !important;
  border: 1px solid $danger !important;
  &:hover {
    border-color: $danger !important;
  }
  &:focus {
    border-color: $danger !important;
    box-shadow: inset 0 0 0 1px $danger !important;
  }
}

.p-dropdown-filter input {
  border: none !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.input-on-button {
  width: 3.8rem;
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
  &:active {
    border-color: $primary_system_active !important;
    box-shadow: inset 0 0 0 1px $primary_system_active !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $primary_system_active !important;
    box-shadow: inset 0 0 0 1px $primary_system_active !important;
    transition: 0.5s;
  }
}

.input-icon-invalid {
  cursor: pointer;
  color: $danger !important;
}

.input-invalid {
  color: $danger !important;
}

.input-invalid-blue {
  color: blue !important;
}

.settings-save-button {
  height: 2.5rem;
  width: 6rem;
  background-color: $primary_system !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
  border: 1px solid $primary_system !important;
  &:active {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
  &:focus {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.5s;
  }
}

.settings-text {
  font-size: 0.9rem;
}

.settings-save-color {
  background-color: $success !important;
  border-color: 0px !important;
  border-radius: 0px !important;
  color: #f9f9f9 !important;
  min-width: 6rem;
}

.settings-checkbox-selected div {
  background: $primary_system !important;
  border-color: $primary_system !important;
  box-shadow: none !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:active {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:hover {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
}

.settings-checkbox-disabled div {
  background: white !important;
  border-color: $primary_system_alpha !important;
  box-shadow: none !important;
  opacity: 0.5;
}

.settings-checkbox div {
  background: $white !important;
  border-color: $primary_system !important;
  box-shadow: none !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:active {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
  &:hover {
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
}

.settings-search-input {
  width: 5rem;
  height: 2rem;
  color: $primary_system_alpha !important;
  border-color: $primary_system_alpha !important;
  border-radius: 0px !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.alert-password {
  color: $red !important;
}
