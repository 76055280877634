$primary_system: #942222;
$secondary_system: #fff;
$background_system: #f4f4f4;
$primary_system_alpha: rgba(51, 51, 51, 0.6);
$primary_system_beta: rgba(255, 193, 193, 0.6);
$muted_system: #a4a6b3;
$text_system: #4d0008;
$primary_system_active: #661313;
$primary_system_lighter: #a72a2a;
$primary_system_darker: #581515;
$link_cansho: #69c984;
$item: #8e8e8e;
$item_hover: rgba(142, 142, 142, 0.6);
$modal_above: rgba(0, 0, 0, 0.37);
$blue: #0d6efd;
$indigo: #6610f2;
$purple: #6f42c1;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #ffc107;
$green: #198754;
$teal: #20c997;
$cyan: #0dcaf0;
$white: #fff;
$gray: #6c757d;
$gray_dark: #343a40;
$primary: #0d6efd;
$secondary: #6c757d;
$success: #28a745;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;

.bg-primary-system {
  background-color: $primary_system !important;
  border-color: $primary_system !important;
}

.text-danger {
  color: $danger !important;
}
