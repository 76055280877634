@import '../../colors/colors.scss';

.sideMenu-extended {
  overflow: hidden;
  height: 100vh;
  width: 15rem;
  float: left;
  background-color: $primary_system;
  transition: width 0.4s;
  position: sticky;
  right: 0;
  top: 0;
  scrollbar-width: thin;
  &:hover {
    position: sticky;
    overflow: auto;
  }
}

.sideMenu-above {
  overflow: auto;
  height: 100%;
  width: 15rem;
  float: left;
  background-color: $primary_system;
  transition: width 0.5s;
  position: fixed !important;
  z-index: 3;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.sideMenu-blocker-above {
  z-index: 2;
  background: $primary_system_alpha;
  position: fixed;
  width: 100vw;
  height: 100%;
}

.sideMenu-closed {
  overflow: hidden;
  height: 100vh;
  width: 4.6rem;
  float: left;
  background-color: $primary_system;
  transition: 0.4s;
  position: sticky;
  right: 0;
  top: 0;
  scrollbar-width: thin;
  &:hover {
    overflow: auto;
    position: sticky;
  }
}

.sideMenu-button-close {
  cursor: pointer;
  color: $secondary_system;
  border: 2px solid $muted_system;
  font-size: 1.8rem;
  padding: 0.2rem;
  border-radius: 100%;
  &:hover {
    color: $muted_system;
    border: 2px solid $secondary_system;
  }
  &:active {
    color: $muted_system;
    border: 2px solid $secondary_system;
    padding: 0.5rem;
  }
}

.user-info {
  height: 8rem;
}

.user-info-data-extended {
  overflow: hidden;
  display: none;
  width: auto !important;
  opacity: 1;
  transition-delay: 1s !important;
  transition: width 2s;
}

.user-info-data-closed {
  overflow: hidden;
  width: 0 !important;
  opacity: 0;
  transition-delay: 1s !important;
  transition: width 2s;
}

.user-info-name-extended {
  color: $white;
  font-size: 1rem;
  transition: font-size 0.5s;
}

.user-info-name-closed {
  color: $white;
  font-size: 0rem;
}

.user-info-function-extended {
  color: $muted_system;
  font-size: 0.6rem;
  transition: font-size 0.5s;
}

.user-info-function-closed {
  color: $muted_system;
  font-size: 0rem;
}

.user-info-line-left-extended {
  border-left: 2px solid $secondary_system;
  height: 4rem !important;
  opacity: 1;
  visibility: visible;
  transition: height 1s;
}

.user-info-line-left-closed {
  border-left: 0px solid $secondary_system;
  height: 0px !important;
  opacity: 0;
  visibility: hidden;
  display: none;
}

.user-info-line-bottom-extended {
  border-top: 2px solid $secondary_system;
  width: 0rem !important;
  opacity: 0;
}

.user-info-line-bottom-closed {
  border-top: 2px solid $secondary_system;
  width: 3.6rem !important;
  opacity: 1;
  transition: opacity 0.5s 0.5s;
}

.user-image {
  border: 2px solid $secondary_system !important;
  color: $primary_system !important;
  font-size: 2rem !important;
  border-radius: 18px !important;
  height: 3.5rem !important;
  width: 3.5rem !important;
}
