@import '../../../styles/global/colors.scss';

.selected-client-target {
  display: flex;
  flex-flow: column nowrap;
  background-color: $white;
  height: 100%;
  .header {
    width: 100%;
    height: 3rem;
    background-color: $primary_system;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    column-gap: 1rem;
    color: $white;
  }
  .button {
    border-radius: 0px;
    background-color: $white;
    color: $primary_system;
    border: 1px solid $white;
    &:hover {
      background-color: $primary_system_alpha !important;
      border: 1px solid $primary_system_alpha !important;
    }
    &:active {
      background-color: $white !important;
      border: 1px solid $white !important;
      color: $primary_system;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .info {
    padding: 1rem;
    display: flex;
    flex-flow: column nowrap;
    height: fit-content;
    .header {
      @media screen and (max-width: 768px) {
        font-size: 0.8rem;
        flex-grow: 1;
      }
      @media screen and (min-width: 768px) {
        font-size: 0.9rem;
        flex-grow: 5;
      }
      height: fit-content;
      color: $dark;
      background-color: $white;
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      justify-items: center;
      align-items: center;
      padding: 0 1rem;
      .content {
        flex: 1;
        display: flex;
        flex-flow: row nowrap;
        flex-grow: 3;
        border-bottom: 2px solid $primary_system;
        margin-top: 0.5rem;
        .title {
          flex: 2;
          font-weight: bolder;
          text-align: left;
        }
        .answer {
          flex: 3;
          width: 100%;
          text-align: center;
          margin: 0;
        }
      }
    }
    .menu {
      margin-top: 1rem;
      width: 100%;
      height: 3rem;
      background-color: $primary_system;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 0.5rem 1rem;
      column-gap: 1rem;
      color: $white;
      .dropdown {
        max-width: 15rem;
        font-size: 0.5rem;
      }
    }
  }
}
