@import '../../colors/colors.scss';

#chart {
  margin: 15px;
}

.dashboard-display {
  min-height: calc(-8rem + 100vh);
  width: 100%;
  overflow: auto;
}

.dashboard-background {
  min-height: calc(-6rem + 100vh);
  width: 100%;
  overflow: auto;
}

.dashboard-box-welcome {
  background-color: $white;
  color: $primary_system;
  height: 10rem;
}

.dashboard-box-stats {
  background-color: $white;
  color: $primary_system;
  height: 6rem;
}

.dashboard-box-stats-icon {
  background-color: $primary_system;
  color: $secondary_system;
  font-size: 3rem;
  width: 50%;
  height: 100%;
}

.dashboard-box-stats-icon-width {
  max-width: 2.5rem;
  max-height: 2.5rem;
}

.dashboard-box-stats-content {
  font-size: 2rem;
  color: $primary_system;
  width: 50%;
  height: 100%;
}

.dashboard-box-stats-muted {
  font-size: 1rem;
  color: $muted_system;
}

.dashboard-box-graphics {
  background-color: $white;
  color: $primary_system;
  height: 20rem;
  overflow-x: auto;
  overflow-y: hidden;
}

.dashboard-box-order {
  height: 14.3%;
}

.dashboard-box-order-status {
  background-color: $white;
  color: $primary_system;
  height: 100%;
}
.dashboard-box-order-numbers {
  background-color: $primary_system;
  color: $white;
  border: 1px solid white;
  height: 100%;
}

.dashboard-box-charts {
  height: 20rem;
  width: 100%;
  min-width: 40rem;
  overflow-x: auto;
  overflow-y: hidden;
}
