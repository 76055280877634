@import '../../../styles/global/colors.scss';

.selected-sales-target-header {
  width: 100%;
  height: 3rem;
  background-color: $primary_system;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  column-gap: 1rem;
  color: $white;
  .dropdown {
    max-width: 15rem;
    font-size: 0.5rem;
  }
  p {
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
}
