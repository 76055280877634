.basic-drop-down {
  width: 100%;
  height: 100%;
  .p-inputtext {
    font-size: 0.8rem !important;
  }
}
.p-inputwrapper-focus {
  .p-dropdown:not(.p-disabled).p-focus > div,
  .p-multiselect:not(.p-disabled).p-focus > div {
    box-shadow: inset 0 0 0 1px pink !important;
  }
  .p-dropdown:not(.p-disabled).p-focus > div {
    border-color: pink !important;
  }
}
