.fontSize1Rem {
  font-size: 1rem !important;
}
.fontSize1-3Rem {
  font-size: 1.3rem !important;
}

.fontSize1-2Rem {
  font-size: 1.2rem !important;
}

.fontSize1-5Rem {
  font-size: 1.5rem !important;
}
.fontSize1-6Rem {
  font-size: 1.6rem !important;
}

.fontSize2Rem {
  font-size: 2rem !important;
}
.fontSize1-1Rem {
  font-size: 1.1rem !important;
}
.fontSize1-2Rem {
  font-size: 1.2rem !important;
}

// Novos
.fontSize3-5rem {
  font-size: 3.5rem !important;
}
.fontSize3rem {
  font-size: 3rem !important;
}
