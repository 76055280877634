@import '../../colors/colors.scss';

.input-image-button {
  width: 100%;
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  min-width: 6rem;
  cursor: pointer;
  &:hover {
    background-color: $primary_system_darker !important;
    transition: 0.2s;
  }
  &:active {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.2s;
  }
  &:focus {
    border-color: $secondary !important;
    box-shadow: inset 0 0 0 1px $secondary !important;
    transition: 0.2s;
  }
}

.input-image-label {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 10rem !important;
}

.input-image-preview-box {
  width: 150px !important;
  height: 150px !important;
  background-color: $background_system;
}

.input-image-preview {
  max-width: 150px !important;
  max-height: 150px !important;
}

.input-warn {
  font-size: 0.8rem;
  color: $muted_system;
}

.input-exclamation {
  font-size: 0.8rem;
  color: $primary_system_lighter;
}

.input-progress-bar div {
  background-color: $primary_system !important;
}
