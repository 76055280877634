.sales-target {
  display: flex;
  flex-flow: column nowrap;
  row-gap: 1rem;
  margin: 0 !important;
}

.skel {
  height: 100% !important;
  min-height: 30rem;
}
