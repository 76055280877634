@import '../../../styles/global/colors.scss';

.goal-template {
  font-weight: 500;
  color: black;
}

.feedback-template {
  font-weight: 500;
  &.good {
    color: $success;
  }
  &.regular {
    color: $warning;
  }
  &.bad {
    color: $danger;
  }
  &.none {
    color: black;
  }
}

.status-body-template {
  color: $white;
  padding: 0.2rem;
  border-radius: 2px;
  font-size: 0.8rem;
  user-select: none;
  &.opened {
    background-color: $success;
  }
  &.not-created {
    background-color: $danger;
  }
}

.status-template {
  padding: 0.2rem;
  border-radius: 2px;
  user-select: none;
  &.opened {
    color: $success;
  }
  &.not-created {
    color: $danger;
  }
}
.progress-bar-body {
  small {
    color: $primary_system;
  }
  .progress-bar-template {
    height: 0.3rem !important;
    div {
      background-color: $primary_system !important;
    }
  }
}
