@import '../../../styles/global/colors.scss';

.client-properties-table {
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  @media screen and (max-width: 768px) {
    padding: 0.5rem;
  }
  @media screen and (min-width: 768px) {
    padding: 0.5rem 3rem;
  }
  .header-subtable {
    width: 100%;
    height: 3rem;
    background-color: $primary_system;
    color: $white;
    padding: 0.5rem;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: flex-start;
    justify-items: center;
    h4 {
      font-weight: 300;
      margin: 0;
      span {
        font-weight: 500;
      }
    }
  }
}
