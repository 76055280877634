@import '../../../styles/global/colors.scss';

.slider-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  label {
    display: block;
    width: fit-content;
    z-index: 1;
    font-size: 0.8rem;
    color: $primary_system;
    user-select: none;
    &.invalid {
      color: $danger;
    }
  }

  div {
    border-radius: 0%;
    background-color: $item_hover;
    span {
      background-color: $primary_system !important;
      border-color: $primary_system_darker !important;
    }
  }

  .errors div {
    background-color: $white !important;
    span {
      background-color: $white !important;
    }
  }
}
