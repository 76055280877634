@import '../../colors/colors.scss';

.addressList-icon-display {
  height: 2rem;
  width: 2rem;
  background-color: $primary_system !important;
}

.addressList-icon-icon {
  color: $white !important;
}

.addressList-button-remove {
  height: 2rem;
  background-color: $primary_system !important;
  border: 1px solid $primary_system !important;
  border-radius: 0;
}
