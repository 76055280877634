@import '../../colors/colors.scss';

.footer-display-login {
  background-color: $white;
  width: 100%;
  height: 3rem;
  color: $text_system;
  z-index: 1;
}

.footer-display-menu-extended {
  background-color: $white;
  color: $text_system;
  height: 3rem;
  transition: 0.5s;
  z-index: 1;
}

.footer-display-menu-closed {
  background-color: $white;
  color: $text_system;
  height: 3rem;
  transition: 0.5s;
  z-index: 1;
}

.mantainer-link {
  color: $link_cansho;
  text-decoration: none;
  z-index: 1;
  cursor: pointer;
}
