@import '../../colors/colors.scss';

.fc .fc-button-primary {
  background-color: $primary_system !important;
  color: white !important;
  border-color: $primary_system !important;
  &:hover {
    transition: 0.5s;
    background: $primary_system !important;
    border-color: $primary_system !important;
  }
  &:active {
    background-color: $primary_system !important;
    border-color: $primary_system !important;
  }
  &:focus {
    background-color: $primary_system_active;
    background: $primary_system_active !important;
    border-color: $primary_system_active !important;
    box-shadow: 0 0 0 0 $primary_system_active !important;
  }
}

.fc .fc-daygrid-day-frame {
  cursor: pointer;
}

.fc-day-today {
  background-color: $primary_system_beta !important;
}

.fc-theme-standard .fc-popover {
  background-color: white !important;
}

.visits-button button {
  background-color: $primary_system !important;
  border-color: $primary_system !important;
  color: white;
  &:active {
    background-color: $primary_system !important;
    border-color: $primary_system !important;
  }
  &:focus {
    background-color: $primary_system !important;
    border-color: $primary_system !important;
    box-shadow: none !important;
  }
}

.visits-button button {
  color: white !important;
}

.settings-input-calendar input {
  width: 100% !important;
  background-color: $background_system !important;
  border: none !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}

.settings-input-calendar {
  width: 100% !important;
}

.cancelledVisitColor {
  color: red;
}

.endVisitColor {
  color: green;
}

.VisitColor {
  color: rgb(255, 145, 0);
}
.visits-input {
  width: 18rem;
  background-color: $background_system !important;
  border: none !important;
  &:focus {
    border-color: $primary_system !important;
    box-shadow: inset 0 0 0 1px $primary_system !important;
  }
}
