@import '../../colors/colors.scss';

.notification-box-position {
  position: fixed;
  right: 0;
  margin-right: 1.38rem;
  top: 2.4rem;
}

.notification-box-display {
  background-color: $primary_system;
  width: 15.3rem;
  color: $secondary_system;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notification-arrow {
  width: 1rem;
  height: 1rem;
  left: 59.5%;
  position: relative;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-bottom: 35px solid $primary_system;
}

.notification-text-header {
  font-size: 1.5rem;
}

.notification-times {
  cursor: pointer;
  &:hover {
    color: $secondary;
  }
}

.notification {
  background-color: $primary_system;
  border-bottom: 1px solid $secondary;
  height: 6rem;
  &:hover {
    background-color: $primary_system_active;
    cursor: pointer;
  }
}

.notification-badge {
  color: $secondary_system;
  font-size: 0.9rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.notification-expire {
  font-size: 0.75rem;
  text-align: justify;
}
