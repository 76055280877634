@import '../../../styles/global/colors.scss';

.seller-sales-target {
  display: flex;
  flex-flow: column nowrap;
  background-color: $white;
  height: 100%;
  .header {
    width: 100%;
    height: 3rem;
    background-color: $primary_system;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    column-gap: 1rem;
    color: $white;
  }
  .dropdown {
    max-width: 15rem;
    font-size: 0.5rem;
  }
  .button {
    border-radius: 0px;
    background-color: $primary_system;
    color: $white;
    border: 1px solid $primary_system;
    &:hover {
      background-color: $primary_system_darker !important;
      border: 1px solid $primary_system_darker !important;
    }
    &:active {
      background-color: $primary_system !important;
      border: 1px solid $primary_system !important;
    }
    &:focus {
      box-shadow: none;
    }
  }
}
