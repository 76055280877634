@import '../../../styles/global/colors.scss';

.stylized-drop-down {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  label {
    display: block;
    width: fit-content;
    padding: 0 0.4rem;
    position: relative;
    top: 0.6rem;
    left: 0.37rem;
    background-color: white;
    z-index: 1;
    font-size: 0.8rem;
    color: $primary_system;
    &.invalid {
      color: $danger;
    }
  }
}
