@import '../../../styles/global/colors.scss';

.create-sales-target {
  background-color: $white;
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  .header {
    width: 100%;
    height: 3rem;
    background-color: $primary_system;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    column-gap: 1rem;
    color: $white;
  }
  .box {
    @media screen and (max-width: 768px) {
      width: 80%;
    }
    @media screen and (min-width: 768px) {
      width: 40%;
    }
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding: 5rem 0px;
    row-gap: 1rem;
    h4 {
      margin: 0;
      text-align: center;
    }
    .button {
      border-radius: 0px;
      background-color: $primary_system;
      color: $white;
      border: 1px solid $primary_system;
      &:hover {
        background-color: $primary_system_darker !important;
        border: 1px solid $primary_system_darker !important;
      }
      &:active {
        background-color: $primary_system !important;
        border: 1px solid $primary_system !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
  }
}
