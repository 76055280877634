@import '../../../styles/global/colors.scss';

.pending-sales-target {
  width: 100%;
  height: 100%;
  min-height: 8rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  justify-items: center;
  padding: 2rem 0px;
  background-color: $white;
}
