@import '../../colors/colors.scss';

.color-default {
  color: rgb(167, 161, 161) !important;
}
.color-selected {
  color: $primary_system;
}
.color-default-responsive {
  background-color: $primary_system_alpha !important;
  border-radius: 0px !important;
  border-color: $primary_system_alpha !important;
  color: $secondary_system !important;
  width: 100%;
  height: 2.5rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}
.color-selected-responsive {
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  width: 100%;
  height: 2.5rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}
.cancelled {
  color: red;
}
.created {
  color: gray;
}
.processing {
  color: lightblue;
}
.processed {
  color: blue;
}
.sended {
  color: rgb(255, 145, 0);
}
.finished {
  color: green;
}

.button-order {
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  min-width: 6rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}

.button-order-responsive {
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  min-width: 9rem;
  height: 2.5rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}

.button-order-responsive-modal {
  background-color: $primary_system !important;
  border-radius: 0px !important;
  border-color: $primary_system !important;
  color: $secondary_system !important;
  width: 100%;
  height: 2.5rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}

.button-product-responsive-modal {
  background-color: $primary_system_alpha !important;
  border-radius: 0px !important;
  border-color: $primary_system_alpha !important;
  color: $secondary_system !important;
  width: 100%;
  height: 2.5rem;
  &:focus {
    box-shadow: inset 0 0 0 1px $secondary_system !important;
  }
}

.order-timeline-circle-default {
  color: rgb(199, 195, 195) !important;
  background-color: white;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  z-index: 1;
  border: 3px rgb(199, 195, 195);
  border-style: solid;
  font-size: 1.3rem;
  cursor: pointer;
  transition: 1s;
}
.order-timeline-line-default {
  width: 100%;
  height: 5px;
  background-color: rgb(167, 161, 161);
  position: relative;
  top: 5.3rem;
  z-index: 0;
  transition: 1s;
}

.order-timeline-circle-selected {
  background-color: white;
  width: 4rem;
  height: 4rem;
  border-radius: 100%;
  z-index: 1;
  border: 3px $primary_system;
  border-style: solid;
  font-size: 1.3rem;
  cursor: pointer;
  transition: 1s;
}

.order-timeline-line-selected {
  width: 100%;
  height: 5px;
  background-color: $primary_system;
  position: relative;
  top: 5.3rem;
  z-index: 0;
  transition: 1s;
}
.order-separated-icon {
  cursor: pointer;
  font-size: 1.2rem;
}
.order-trash-icon {
  color: $primary_system;
  font-size: 1.2rem;
  cursor: pointer;
}
.order-envelop-icon {
  color: $primary_system;
  font-size: 1.2rem;
  cursor: pointer;
}
.order-envelop-icon-disabled {
  color: $primary_system_alpha;
  font-size: 1.2rem;
  opacity: 0.5;
}

.order-envelop-icon-responsive {
  color: $primary_system;
  font-size: 2rem;
  cursor: pointer;
}
.order-envelop-icon-disabled-responsive {
  color: $primary_system_alpha;
  font-size: 2rem;
  opacity: 0.5;
}

.item-box-future {
  min-height: 3rem;
  background-color: rgba(255, 193, 193, 0.6);
  &:hover {
    background-color: rgba(252, 149, 149, 0.6);
    transition: background-color 0.5s;
  }
}
.order-icon-future {
  color: rgba(255, 193, 193, 0.6);
}
.order-trash-icon-disabled {
  color: $primary_system_alpha;
  font-size: 1.2rem;
  opacity: 0.5;
  cursor: pointer;
}
.order-product-img-disabled div p img {
  opacity: 0.5;
}
.order-disabled-text {
  color: rgba(51, 51, 51, 0.6) 3;
  opacity: 0.5;
}
.order-checkbox {
  font-size: 2.3rem;
  color: $primary_system;
  cursor: pointer;
}
.order-checkbox-reservation {
  color: $primary_system;
  font-size: 2.3rem;
  cursor: pointer;
}
.item-box-service {
  min-height: 3rem;
  background-color: $background_system;
  border: 0.1rem $primary_system;
  border-style: solid;
  &:hover {
    background-color: $item_hover;
    transition: background-color 0.5s;
  }
}
.order-service-chevron {
  color: $primary_system;
  font-size: 2rem;
  cursor: pointer;
}

.order-service-chevron-disabled {
  color: rgb(143, 138, 138) !important;
  font-size: 2rem;
}
