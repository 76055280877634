@import '../../../styles/global/colors.scss';

// Corrigir Flex

.sales-target-header {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  justify-items: center;
  align-items: center;
  column-gap: 1rem;
  background-color: $white;
  padding: 1rem;
  .header-img {
    width: 10%;
    width: 5rem;
    height: 5rem;
    background-color: $item_hover;
    border-radius: 15px;
    @media screen and (max-width: 768px) {
      display: none;
    }
    @media screen and (min-width: 768px) {
      display: block;
    }
  }
  .business-info {
    @media screen and (max-width: 768px) {
      width: 100%;
      text-align: center;
      margin-bottom: 1rem;
    }
    @media screen and (min-width: 768px) {
      width: 20%;
    }
    display: flex;
    flex-flow: column wrap;
    justify-content: center;
    justify-items: center;
    p {
      margin: 0;
    }
  }
  .sales-info {
    flex: 1;
    border-left: 2px solid $primary_system;
    padding: 0.5rem;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: center;
    text-align: center;
    .box {
      h1 {
        margin: 0;
      }
      h3 {
        margin: 0;
      }
    }
  }
  .alpha-text {
    color: $primary_system_alpha;
  }
}
