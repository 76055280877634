@import '../../../styles/global/colors.scss';

.range-date-input {
  width: 100%;
  height: 100%;
  display: flex;
  flex-flow: column nowrap;
  label {
    display: block;
    width: fit-content;
    padding: 0 0.4rem;
    position: relative;
    top: 0.6rem;
    left: 0.37rem;
    background-color: white;
    z-index: 1;
    font-size: 0.8rem;
    color: $primary_system;
    &.invalid {
      color: $danger;
    }
  }

  span {
    &.invalid {
      input {
        border: 1px solid $danger !important;
        box-shadow: inset 0 0 0 0px $danger !important;
        &:hover {
          box-shadow: inset 0 0 0 1px $danger !important;
          border-color: $danger !important;
        }
        &:focus {
          box-shadow: inset 0 0 0 1px $danger !important;
          border-color: $danger !important;
        }
      }
    }
    input {
      border: 1px solid $primary_system !important;
      width: 100% !important;
      background-color: white !important;
      box-shadow: inset 0 0 0 0px $primary_system !important;
      &:hover {
        outline: 0 none !important;
        outline-offset: 0 !important;
        box-shadow: inset 0 0 0 1px $primary_system !important;
        border-color: $primary_system !important;
      }
      &:focus {
        outline: 0 none !important;
        outline-offset: 0 !important;
        box-shadow: inset 0 0 0 1px $primary_system !important;
        border-color: $primary_system !important;
      }
    }
  }
}
